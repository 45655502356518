import { generateTruckPlanString } from "../../functions/generalFunctions"
import { addGatewayToMainDB } from "../../functions/gatewayFunctions"

function GSPairsGatewaysTable({group}) {
    return (
        <table className="gateway-table">
            <tr className="table-headers">
                <th>Gateway Information</th>
            </tr>
            <tr className="row"><p>Customer ID:</p><p>{group.customer_id ? group.customer_id : ""}</p></tr>
            <tr className="row"><p>Gateway serial:</p><p>{group.gateway_serial}</p></tr>
            <tr className="row"><p>Gateway name:</p><p>{group.gateway_name}</p></tr>
            {
                group.truck_plans.map((plan) => 
                    <>
                        <tr className="row"><p>Truck Plan:&nbsp;</p><p>{generateTruckPlanString(plan)}</p></tr>
                        <tr className="row"><button onClick={() => addGatewayToMainDB({...group, truck_plan: plan})} style={{margin: "auto"}}>Add gateway with this truck plan</button></tr>
                    </>
                )
            }
        </table>
    )
}

export default GSPairsGatewaysTable