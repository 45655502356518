function GSPairsTableHeader({setFilter}) {
    return (
        <select onChange={(v) => setFilter(v.target.value)} className="table-filters">
            <option value={"UNPROCESSED"}>UNPROCESSED</option> 
            <option value={"PROCESSED"}>PROCESSED</option> 
            <option value={"JUNK"}>JUNK</option> 
        </select>
    )
}

export default GSPairsTableHeader