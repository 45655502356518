export default async function addGatewayToMainDB(pair) {
    // create suitable data for the main DB routes
    const gatewayPayload = {
        customer_id: pair.customer_id,
        hub_name: pair.gateway_name,
        hub_serial: pair.gateway_serial,
        truck_plan: pair.truck_plan
    }

    // check for unfilled fields 
    if (!gatewayPayload.customer_id) {
        alert("ERROR: Failed to add to main database. Did not enter customer ID for the gateway. Please do this in the gateways table.")
        return;
    }

    // add to gateway route, display any errors
    try {
        const response = await fetch("https://backupdroplet.mesomat.org/api/install/gateway", 
            {headers: {'Content-Type': 'application/json', 'token': localStorage.getItem("backendPassword")}, 
            method: "PUT", 
            body: JSON.stringify(gatewayPayload)})
        const data = await response.json()
        
        if (data.status_code === 200) {
            console.log(data)
            alert(`Gateway ${pair.gateway_serial} created successfully`)
        }
        else {
            console.log(data)
            alert(data.response_body.detail)
        }
    }
    catch (error) {
        alert(error)
    }
}