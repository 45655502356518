function GatewayTableRow({gateway, tableSettings, changeGatewayProcessedFlag, changeGatewayCustomerID}) {
    return (
        <tr key={gateway.id + gateway.processed_flag}>
            <td>{gateway.id}</td>
            <td>{gateway.gateway_serial}</td>
            <td>{gateway.gateway_name}</td>
            <td>{gateway.customer_name}</td>
            {tableSettings.show_device_id && <td>{gateway.device_id}</td>}
            {tableSettings.show_timestamp_added && <td>{gateway.timestamp_added}</td>}
            <td>
                <input type="text"
                    onBlur={(v) => changeGatewayCustomerID(gateway.id, v.target.value)} defaultValue={gateway.customer_id}>
                </input>
            </td>
            <td>
                <select onChange={(v) => changeGatewayProcessedFlag(gateway, v.target.value)}>
                    <option value={"100"} selected={gateway.processed_flag === "100" ? true : false}>100 (PROCESSED)</option>    
                    <option value={"200"} selected={gateway.processed_flag === "200" ? true : false}>200 (UNPROCESSED)</option>
                    <option value={"150"} selected={gateway.processed_flag === "150" ? true : false}>150 (JUNK)</option>        
                </select>
            </td>
        </tr>
    )
}

export default GatewayTableRow