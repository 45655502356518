import { generateTireLocationString } from "../../functions/generalFunctions"
import { generateTruckPlanString } from "../../functions/generalFunctions"

function SensorTableRow({sensor, gatewayData, tableSettings, changeSensorGatewayPair}) {
    return (
        <tr key={sensor.id + sensor.paired_gateway}>
            <td>{sensor.id}</td>
            <td>{sensor.sensor_barcode}</td>
            <td>{generateTireLocationString(sensor)}</td>
            <td>{generateTruckPlanString(sensor.truck_plan)}</td>
            {tableSettings.show_device_id && <td>{sensor.device_id}</td>}
            {tableSettings.show_timestamp_added && <td>{sensor.timestamp_added}</td>}
            <td>
              <input type="text" list="gateway_pairs"
                onBlur={(v) => changeSensorGatewayPair(sensor.id, v.target.value)} defaultValue={sensor.paired_gateway}>
              </input>
              <datalist id="gateway_pairs">
                  <option value={"-1"}>JUNK</option>
                  {[... new Set(gatewayData.map((x) => x.gateway_serial))].map((gateway_serial) => 
                    <option>{gateway_serial}</option>
                  )}
              </datalist>
            </td>
        </tr>
    )
}

export default SensorTableRow