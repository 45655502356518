import { generateTireLocationString } from "../../functions/generalFunctions"
import { addSensorToMainDB, addAllSensorToMainDB } from "../../functions/sensorFunctions"

function GSPairsSensorsTable({group}) {
    return (
        <table className="sensors-table">
            <tr className="table-headers">
                <th>Sensor Barcode</th>
                <th>Tire Position</th>
                <th>Add Sensor</th>
            </tr>
            {group.sensors.map((sensor) => 
                <tr>
                    <td style={{textAlign: "center"}}>{sensor.sensor_barcode}</td>
                    <td style={{textAlign: "center"}}>{generateTireLocationString(sensor)}</td>
                    <td style={{textAlign: "center"}}><button onClick={() => addSensorToMainDB(sensor)}>Add sensor</button></td>
                </tr>
            )}
            <tr>
                <td></td>
                <td></td>
                <td style={{textAlign: "center"}}><button onClick={() => addAllSensorToMainDB(group.sensors)}>Add All</button></td>
            </tr>
        </table>
    )
}

export default GSPairsSensorsTable