import {fetchGateways} from './index'

// TODO: create backend route to handle this instead
// TODO: possibly do a comparison and only raise alert if values are different?
export default async function checkExistingProcessed(gatewaySerial, flagValue) {
    if (flagValue === "100") {
        const gateways = await fetchGateways()

        for (const gateway of gateways) {
            if (gateway.gateway_serial === gatewaySerial && gateway.processed_flag === "100") {
                alert("WARNING: Another gateway with the same serial number already has flag 100. This will lead to unexpected results if there is conflicting data.")
                break;
            }
        }
    }
}