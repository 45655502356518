function SensorTableHeader({tableSettings}) {
    return (
        <tr>
          <th>Staging Area ID</th>
          <th>Sensor Barcode</th>
          <th>Tire Position</th>
          <th>Truck Plan</th>
          {tableSettings.show_device_id && <th>Device ID</th>}
          {tableSettings.show_timestamp_added && <th>Timestamp Added</th>}
          <th>Paired Gateway (Serial)</th>
        </tr>
    )
}

export default SensorTableHeader

