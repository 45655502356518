import { useState } from "react";
import GatewayTable from "./pages/gatewayTable/gatewayTable";
import SensorTable from "./pages/sensorTable/sensorTable";
import GatewaySensorPairsTable from "./pages/gatewaySensorPairsTable/gatewaySensorPairsTable";
import { login, logout } from "./functions/generalFunctions";


function App() {
  const [display, setDisplay] = useState("GATEWAY")

  return (
    <div className="App">
      {!localStorage.getItem("backendPassword") ?
      <>
        {/* login */}
        <form onSubmit={login}>
          <label for="login-password">password:</label>
          <input id="login-password" name="login-password"></input>
          <button type="submit">Submit</button>
        </form>
      </>
      :
      <>
        {/* main app */}
        <div className="navigation-buttons">
          <button onClick={() => setDisplay("GATEWAY")}>Gateway</button>
          <button onClick={() => setDisplay("SENSOR")}>Sensor</button>
          <button onClick={() => setDisplay("PAIRS")}>Gateway-Sensor Pairs</button>
          <button style={{marginLeft: "auto"}} onClick={logout}>Logout</button>
        </div>
        {display === "GATEWAY" && <GatewayTable />}
        {display === "SENSOR" && <SensorTable />}
        {display === "PAIRS" && <GatewaySensorPairsTable />}
      </>
      }
    </div>
  );
}

export default App;
