function GatewayTableFilterButtons({tableSettings, setTableSettings, setFilter}) {
    return (
        <div className="table-filters">
            <button onClick={() => setTableSettings({...tableSettings, show_device_id: !tableSettings.show_device_id})}>
            {tableSettings.show_device_id ? "Hide" : "Show"} Device ID
            </button>
            <select onChange={(v) => setFilter(v.target.value)}>
                <option value={"UNPROCESSED"}>UNPROCESSED</option> 
                <option value={"PROCESSED"}>PROCESSED</option> 
                <option value={"JUNK"}>JUNK</option> 
            </select>
            <button onClick={() => setTableSettings({...tableSettings, show_timestamp_added: !tableSettings.show_timestamp_added})}>
                {tableSettings.show_timestamp_added ? "Hide" : "Show"} timestamp Added
            </button>
        </div>
    )
}

export default GatewayTableFilterButtons