import _ from "lodash";

/*
Formats the raw gateway-sensor pairs retrieved from the database into groups based on the gateway serial.

Database data:
[{gateway_serial: '172', gateway_name: 'name1', truck_plan: {...}, sensor_barcode: 123, tire_position {…}, ...},
{gateway_serial: '173', gateway_name: 'name2', truck_plan: {...}, sensor_barcode: 456, tire_position {…}, ...},
{gateway_serial: '172', gateway_name: 'name1', truck_plan: {...}, sensor_barcode: 789, tire_position {…}, ...},
...]

|
V

[[gateway_serial: '172', gateway_name: 'name1', truck_plans: [{...}, ...], sensors: [{...}, ...]],
[gateway_serial: '173', gateway_name: 'name2', truck_plans: [{...}, ...], sensors: [{...}, ...]],
...]
*/

export default function formatGatewaySensorPairs(gatewaySensorPairs, gatewaySensorProcessFlags) {
    const groupedPairs = []

    const sortedGatewaySensorPairs = gatewaySensorPairs.sort((pair1, pair2) => pair2.gateway_serial - pair1.gateway_serial)

    for (const pair of sortedGatewaySensorPairs) {
        const pair_has_same_gateway_as_previous_group = groupedPairs.length !== 0 && pair.gateway_serial === groupedPairs[groupedPairs.length - 1].gateway_serial

        if (pair_has_same_gateway_as_previous_group) {
            // add the new sensor to the group
            groupedPairs[groupedPairs.length - 1].sensors.push({sensor_barcode: pair.sensor_barcode, tire_position: pair.tire_position, gateway_serial: pair.gateway_serial})
            // add the new truck_plan if it isn't already in the group
            if (!groupedPairs[groupedPairs.length - 1].truck_plans.some(x => (_.isEqual(x, pair.truck_plan)))) {
                groupedPairs[groupedPairs.length - 1].truck_plans.push(pair.truck_plan)
            }
        }
        // create new group for the new gateway
        else {
            groupedPairs.push({customer_id: pair.customer_id,
                gateway_name: pair.gateway_name,
                gateway_serial: pair.gateway_serial,
                processed_flag: gatewaySensorProcessFlags.find((x) => x.gateway_serial === pair.gateway_serial) ?
                    gatewaySensorProcessFlags.find((x) => x.gateway_serial === pair.gateway_serial).processed_flag : "200",
                truck_plans: [pair.truck_plan], sensors: [{sensor_barcode: pair.sensor_barcode, tire_position: pair.tire_position, gateway_serial: pair.gateway_serial}]})
        }
    }

    return groupedPairs
}