import { useEffect, useState } from "react";
import { fetchGateways, changeGatewayProcessedFlag, changeGatewayCustomerID, filterGateways } from "../../functions/gatewayFunctions";
import GatewayTableFilterButtons from "./gatewayTableFilterButtons";
import GatewayTableHeader from "./gatewayTableHeader";
import GatewayTableRow from "./gatewayTableRow";
import './gatewayTable.css'

/*
Creates the table displaying gateways
Allows user to change the processed_flag and customer_id property for gateways
*/

function GatewayTable() {
    const [gatewaysData, setGatewaysData] = useState([])
    const [tableSettings, setTableSettings] = useState({show_device_id: false, show_timestamp_added: false})
    const [filter, setFilter] = useState("UNPROCESSED")

    // fetch and filter gateways from DB
    useEffect(() => {
        async function fetchData() {
            const fetchedGatewayData = await fetchGateways()
            fetchedGatewayData.sort((gateway1, gateway2) => gateway2.id - gateway1.id)
            const filteredGatewayData = filterGateways(fetchedGatewayData, filter)
            setGatewaysData(filteredGatewayData)
        }
        fetchData()
    }, [filter])

    return (
    <section className="gateways-page">
        <h1>Gateways</h1>

        <GatewayTableFilterButtons tableSettings={tableSettings} setTableSettings={setTableSettings} setFilter={setFilter}/>
        <table className="gateways-table">
            <GatewayTableHeader tableSettings={tableSettings} />
            {gatewaysData.map((gateway) => 
                <GatewayTableRow gateway={gateway} tableSettings={tableSettings} 
                changeGatewayProcessedFlag={changeGatewayProcessedFlag} changeGatewayCustomerID={changeGatewayCustomerID}/>
            )}
        </table>
    </section>
  );
}

export default GatewayTable;
