import { useState, useEffect } from "react"
import { fetchGateways } from "../../functions/gatewayFunctions"
import { fetchSensors, changeSensorGatewayPair, filterSensors } from "../../functions/sensorFunctions"
import SensorTableFilterButtons from "./sensorTableFilterButtons"
import SensorTableHeader from "./sensorTableHeader"
import SensorTableRow from "./sensorTableRow"
import './sensorTable.css'

/*
Creates the table displaying sensors
Allows user to change the paired-gateway property for sensors
*/

function SensorTable() {
    const [sensorData, setSensorData] = useState([])
    const [gatewayData, setGatewayData] = useState([])
    const [tableSettings, setTableSettings] = useState({show_device_id: false, show_timestamp_added: false})
    const [filter, setFilter] = useState("UNPROCESSED")

    // fetch and filter sensors and gateways from DB
    useEffect(() => {
      async function fetchData() {
          const fetchedSensorData = await fetchSensors()
          fetchedSensorData.sort((sensor1, sensor2) => sensor2.id - sensor1.id)
          const filteredSensorData = filterSensors(fetchedSensorData, filter)
          setSensorData(filteredSensorData)

          // gateway data required for paired-gateway autofill
          const fetchedGatewayData = await fetchGateways()
          setGatewayData(fetchedGatewayData)
      }
      fetchData()
    }, [filter])

    return (
      <section className="sensors-page">
        <h1>Sensors</h1>
        
        <SensorTableFilterButtons setTableSettings={setTableSettings} tableSettings={tableSettings} setFilter={setFilter}/>

        <table className="sensors-table">
          <SensorTableHeader tableSettings={tableSettings} />
          {sensorData.map((sensor) => 
            <SensorTableRow sensor={sensor} gatewayData={gatewayData} tableSettings={tableSettings} changeSensorGatewayPair={changeSensorGatewayPair}/>
          )}
        </table>
      </section>
    );
  }
  
  export default SensorTable;
  