export default function filterGateways(gatewayData, filter) {
    let filteredGatewayData;

    if (filter === "PROCESSED") {
        filteredGatewayData = gatewayData.filter((gateway) => gateway.processed_flag === "100")
    }
    else if (filter === "UNPROCESSED") {
        filteredGatewayData = gatewayData.filter((gateway) => gateway.processed_flag === "200")
    }
    else if (filter === "JUNK") {
        filteredGatewayData = gatewayData.filter((gateway) => gateway.processed_flag === "150")
    }

    return filteredGatewayData
}