import generateTireLocationString from "../generalFunctions/generateTireLocationString";

export async function addSensorToMainDB(pair) {
    // create suitable data for the main DB routes
    const sensorPayload = {
        sensor_serial: 120, // dummy value
        barcode: pair.sensor_barcode,
        hub_serial: pair.gateway_serial,
        tire_position: pair.tire_position,
    }

    // check for unfilled fields
    if (!sensorPayload.sensor_serial || !sensorPayload.barcode || !sensorPayload.hub_serial || !sensorPayload.tire_position) {
        alert("Unfilled fields")
        return;
    }

    // add to sensor route, display any errors
    try {
        const response = await fetch("https://backupdroplet.mesomat.org/api/install/sensor", 
            {headers: {'Content-Type': 'application/json', 'token': localStorage.getItem("backendPassword")}, 
            method: "PUT", 
            body: JSON.stringify(sensorPayload)})
        const data = await response.json()
        
        if (data.status_code === 200) {
            console.log(data)
            alert(`Sensor registered on ${generateTireLocationString(pair)}`)
        } 
        else {
            console.log(data)
            alert(data.response_body.detail)
        } 
    }
    catch (error) {
        alert(error)
    }
}

export async function addAllSensorToMainDB(sensors) {
    for (const sensor of sensors) {
        await addSensorToMainDB(sensor)
    }
}