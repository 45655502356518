export default async function login(e) {
    e.preventDefault()

    const response = await fetch("https://backupdroplet.mesomat.org/api/login", 
      {headers: {'Content-Type': 'application/json', 'password': e.target[0].value}, method: "PUT"})
    const data = await response.json()
    
    if (data !== "Incorrect Password") {
      localStorage.setItem("backendPassword", data)
      window.location.reload()
    } else {
      alert(data)
    }
}