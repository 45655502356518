import { useEffect, useState } from "react";
import { fetchGatewaySensorPairs, fetchGatewaySensorProcessedFlag, changeGSPairProcessedFlag, formatGatewaySensorPairs, filterGatewaySensorPairs } from "../../functions/gatewaySensorPairsFunctions";
import GSPairsTableFiltersButtons from "./GSPairsTableFilterButtons";
import GSPairsGatewaysTable from "./GSPairsGatewaysTable";
import GSPairsSensorsTable from "./GSPairsSensorsTable";
import GSPairsProcessedFlagTable from "./GSPairsProcessedFlagTable";
import './gatewaySensorPairsTable.css'

/*
Creates the table displaying sensors that are paired to a gateway
Allows user to add the paired data to the DB
*/

function GatewaySensorPairsTable() {
    const [gatewaySensorPairsData, setGatewaySensorPairsData] = useState([])
    const [filter, setFilter] = useState("UNPROCESSED")

    // fetch, format, filter gateway-sensors pairs from DB
    useEffect(() => {
        async function fetchData() {
            const fetchedGSPairs = await fetchGatewaySensorPairs()
            const fetchedGSProcessedFlag = await fetchGatewaySensorProcessedFlag()
            const formattedGSPairs = formatGatewaySensorPairs(fetchedGSPairs, fetchedGSProcessedFlag)
            const filteredGSPairs = filterGatewaySensorPairs(formattedGSPairs, filter)
            setGatewaySensorPairsData(filteredGSPairs)
        }

        fetchData()
    }, [filter])

    return (
    <section className="gateway-sensor-pairs-page">
        <h1>Gateway-Sensors Pairs</h1>

        <GSPairsTableFiltersButtons setFilter={setFilter} />
        {gatewaySensorPairsData.map((group) => 
            <div className="gateway-sensor-pairs-table">
                <GSPairsGatewaysTable group={group } />
                <GSPairsSensorsTable group={group} />

                <GSPairsProcessedFlagTable group={group} changeGSPairProcessedFlag={changeGSPairProcessedFlag} />
            </div>
        )}
    </section>
  );
}

export default GatewaySensorPairsTable;
