function GSPairsProcessedFlagTable({group, changeGSPairProcessedFlag}) {
    return (
        <table className="processed-table">
            <tr className="table-headers">
                <th>Processed Flag</th>
            </tr>
            <tr>
                <td>
                <select onChange={(v) => changeGSPairProcessedFlag(group.gateway_serial, v.target.value)}>
                    <option value={"100"} selected={group.processed_flag === "100" ? true : false}>100 (PROCESSED)</option>    
                    <option value={"200"} selected={group.processed_flag === "200" ? true : false}>200 (UNPROCESSED)</option>
                    <option value={"150"} selected={group.processed_flag === "150" ? true : false}>150 (JUNK)</option>        
                </select>
                </td>
            </tr>
        </table>
    )
}

export default GSPairsProcessedFlagTable