function GatewayTableHeader({tableSettings}) {
    return (
        <tr>
            <th>Staging Area ID</th>
            <th>Gateway Serial</th>
            <th>Gateway Name</th>
            <th>Customer Name</th>
            {tableSettings.show_device_id && <th>Device ID</th>}
            {tableSettings.show_timestamp_added && <th>Timestamp Added</th>}
            <th>Customer ID</th>
            <th>Processed Flag</th>
        </tr>
    )
}

export default GatewayTableHeader

