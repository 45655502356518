export default function filterGatewaySensorPairs(GSPairData, filter) {
    let filteredGSPairs;

    if (filter === "PROCESSED") {
        filteredGSPairs = GSPairData.filter((pair) => pair.processed_flag === "100")
    }
    else if (filter === "UNPROCESSED") {
        filteredGSPairs = GSPairData.filter((pair) => pair.processed_flag === "200")
    }
    else if (filter === "JUNK") {
        filteredGSPairs = GSPairData.filter((pair) => pair.processed_flag === "150")
    }

    return filteredGSPairs
}