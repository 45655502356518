export default function filterSensors(sensorData, filter) {
    let filteredSensorData;

    if (filter === "JUNK") {
        filteredSensorData = sensorData.filter((sensor) => sensor.paired_gateway === "-1")
    }
    else if (filter === "PROCESSED") {
        filteredSensorData = sensorData.filter((sensor) => sensor.paired_gateway !== "-1" && sensor.paired_gateway !== null)
    }
    else if (filter === "UNPROCESSED") {
        filteredSensorData = sensorData.filter((sensor) => sensor.paired_gateway === null)
    }

    return filteredSensorData
}